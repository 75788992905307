@font-face {
  font-family: 'AvertaPE';
  src: url("/assets/fonts/averta/349C84_5_0.eot");
  src: url("/assets/fonts/averta/349C84_5_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/averta/349C84_5_0.woff2") format("woff2"), url("/assets/fonts/averta/349C84_5_0.woff") format("woff"), url("/assets/fonts/averta/349C84_5_0.ttf") format("truetype"), url("/assets/fonts/averta/349C84_5_0.svg#wf") format("svg");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'AvertaPE';
  src: url("/assets/fonts/averta/349C84_1_0.eot");
  src: url("/assets/fonts/averta/349C84_1_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/averta/349C84_1_0.woff2") format("woff2"), url("/assets/fonts/averta/349C84_1_0.woff") format("woff"), url("/assets/fonts/averta/349C84_1_0.ttf") format("truetype"), url("/assets/fonts/averta/349C84_1_0.svg#wf") format("svg");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'AvertaPE-RegularItalic';
  src: url("/assets/fonts/averta/349C84_2_0.eot");
  src: url("/assets/fonts/averta/349C84_2_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/averta/349C84_2_0.woff2") format("woff2"), url("/assets/fonts/averta/349C84_2_0.woff") format("woff"), url("/assets/fonts/averta/349C84_2_0.ttf") format("truetype"), url("/assets/fonts/averta/349C84_2_0.svg#wf") format("svg");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'AvertaPE';
  src: url("/assets/fonts/averta/349C84_0_0.eot");
  src: url("/assets/fonts/averta/349C84_0_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/averta/349C84_0_0.woff2") format("woff2"), url("/assets/fonts/averta/349C84_0_0.woff") format("woff"), url("/assets/fonts/averta/349C84_0_0.ttf") format("truetype"), url("/assets/fonts/averta/349C84_0_0.svg#wf") format("svg");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'AvertaPE';
  src: url("/assets/fonts/averta/349C84_3_0.eot");
  src: url("/assets/fonts/averta/349C84_3_0.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/averta/349C84_3_0.woff2") format("woff2"), url("/assets/fonts/averta/349C84_3_0.woff") format("woff"), url("/assets/fonts/averta/349C84_3_0.ttf") format("truetype"), url("/assets/fonts/averta/349C84_3_0.svg#wf") format("svg");
  font-style: normal;
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none
}

button,input,select,textarea {
  margin: 0
}

html {
  box-sizing: border-box
}

*,::after,::before {
  box-sizing: inherit;
}

:root {
  --field-bg-color: #fff;
  --field-border-color: #ebedf0;
  --field-color: #5c687e;
  --field-font-size: 14px;
  --field-height: 40px;
  --content-text-color: #262626;
  --default-border-color: #ebedf0;
  --blue-border-color: #149efc;
  --default-blue-color: #149efc;
  --field-disabled-bg-color: hsl(0,0%,95%);
  --field-disabled-border-color: hsl(0,0%,90%);
  --select-border-color: hsl(0, 0%, 80%);
}

/* ------------------------------------------------------------------------------------- */
.customScroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: rgba(204, 213, 225, 1);
  border-radius: 10px;
}

@keyframes rotate{
    0% {
      rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}

.animateRotate {
  transform-origin: var(--center, center);
  animation: rotate 2s linear infinite;
}