.CalendarDay__selected, .CalendarDay__selected:hover {
     background-color: var(--default-blue-color);
     /*color: white;*/
 }

.DateInput_input {
    padding: 7px 11px 4px;
    font-size: 16px;
}

.DateInput_input__focused {
    border-bottom-color: var(--default-blue-color);
}